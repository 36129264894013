import { env } from 'store/env';
import { AppNetworkType } from '../../types/models/NetworkModel';

const currencies = { USD: 'usd', BTC: 'btc', EURO: 'eur', YEN: 'jpy' };
const defaultNetworkType = env.REACT_APP_DEFAULT_NETWORK_TYPE as 'mainnet' | 'testnet';
const defaultSyncInterval = env.REACT_APP_DEFAULT_SYNC_INTERVAL;
const explorerURL = {
  testnet: 'https://explorer-testnet.veritise.com',
  mainnet: 'https://explorer.veritise.com',
};
const syncIntervals = { OFF: 0, '15 MIN': 15, '1 HR': 60, '4 HR': 240, '12 HR': 720 };
const nativeMosaicId = env.REACT_APP_NATIVE_MOSAIC_ID as string;
const nodeTypes = { mainnet: 'mainnet', testnet: 'testnet', CUSTOM: 'custom' };
const networks = {
  mainnet: {
    nisNodes: ['http://hugealice2.nem.ninja', 'http://hachi.nem.ninja', 'http://alice2.nem.ninja'],
    nodes: [
      'https://dual1-mainnet.veritise.com',
      'https://dual2-mainnet.veritise.com',
      'https://dual3-mainnet.veritise.com',
    ],
  },
  testnet: {
    nisNodes: ['http://hugetestalice2.nem.ninja', 'http://hugetestalice.nem.ninja', 'http://medalice2.nem.ninja'],
    nodes: ['https://dual00-testnet.veritise.superhow.net', 'https://dual01-testnet.veritise.superhow.net'],
  },
};
const optInWhiteList = {
  testnet: [],
  mainnet: [],
};
// Session timeout
// const getSessionTimeoutInMillis = (): number => sessionTimeoutInSeconds * 1000;

// Market currency
const getMarketCurrencyLabel = (): string => env.REACT_APP_MARKET_CURRENCY_NAME as string;

// News URL
// const getNewsURL = (): string => newsURL;

// Explorer URL
const getExplorerURL = (network: AppNetworkType): string => explorerURL[network];

// Explorer URL
const getFaucetUrl = (): string => env.REACT_APP_FAUCET_URL as string;

// Explorer URL
// const getAboutURL = (): string => aboutURL;

// Currency
const getCurrencyList = (): Array<string> => Object.keys(currencies);

const getDefaultCurrency = (): string => currencies.USD || 'USD';

const getCurrencyValue = (currencyKey: keyof typeof currencies): string => currencies[currencyKey];

// Lanugage
// const getLanguageList = (): Array<string> => {
//   logger.log(lanugages);
//   return Object.keys(lanugages);
// };

const getDefaultLanguage = (): string => 'en';

// const getLanguageValue = (languageKey: string): string => lanugages[languageKey];

// Notification/Sync interval
const getValidSyncIntervals = (): Array<string> => Object.keys(syncIntervals);

const getDefaultSyncInterval = (): string => defaultSyncInterval || 'OFF';

const getSyncIntervalValue = (intervalKey: keyof typeof syncIntervals): number => syncIntervals[intervalKey];

// Node types
// const getAvailableNodeTypes = (): Array<string> => Object.keys(nodeTypes);

const getDefaultNodeType = (): string => nodeTypes.mainnet || 'mainnet';

const getNodeTypeValue = (nodeTypeKey: keyof typeof nodeTypes): string => nodeTypes[nodeTypeKey];

const isCustomNode = (nodeType: string): boolean => nodeType !== undefined && nodeType === nodeTypes.CUSTOM;

// Network info
// eslint-disable-next-line @typescript-eslint/ban-types
const getNetworkInfo = (nodeType: keyof typeof networks): Object => {
  const networkConfig = networks[nodeType];
  // use mainnet as fallback config
  return networkConfig ? networks.mainnet : networkConfig;
};

const getNISNodes = (network: 'mainnet' | 'testnet' = 'testnet'): string[] => networks[network].nisNodes;

const getDefaultNetworkType = (): 'mainnet' | 'testnet' => defaultNetworkType;

const getNodes = (network: 'mainnet' | 'testnet' = 'testnet'): string[] => networks[network].nodes;

const getNativeMosaicId = (): string => nativeMosaicId;

const getWhitelistedPublicKeys = (network: 'mainnet' | 'testnet' = 'testnet'): string[] => optInWhiteList[network];

// const getFinanceBotPublicKeys = (network: 'mainnnet' | 'testnet' = 'testnet'): string[] =>
//   nglFinanceBot[network];

export {
  getMarketCurrencyLabel,
  getExplorerURL,
  getFaucetUrl,
  getCurrencyList,
  getDefaultCurrency,
  getCurrencyValue,
  getDefaultLanguage,
  getValidSyncIntervals,
  getDefaultSyncInterval,
  getSyncIntervalValue,
  getDefaultNodeType,
  getNodeTypeValue,
  isCustomNode,
  getNetworkInfo,
  getNISNodes,
  getDefaultNetworkType,
  getNodes,
  getNativeMosaicId,
  getWhitelistedPublicKeys,
};
