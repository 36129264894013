import { FC, useEffect, useState } from 'react';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Paper } from '@material-ui/core';
import { ListConfig } from './config';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { formatDate } from 'utils/formatDate';
import { env } from 'store/env';
import { EntityImage } from 'components/EntityImage';

export interface IDetails {
  id: string;
  config: ListConfig<any, any>;
}

const useStyles = makeStyles(() =>
  createStyles({
    primaryTypography: {
      '& p': {
        fontSize: '20px',
      },
      '& span': {
        fontSize: '16px',
      },
    },
  })
);

export const DetailsView: FC<IDetails> = ({ id, config }) => {
  const [data, satData] = useState<any>([]);
  const classes: any = useStyles();

  useEffect(() => {
    config.dataSource.get(id).then((data) => {
      satData(config.mapSourceToValues(data));
    });
  }, [config, id]);

  return (
    <Paper variant="elevation">
      <Box>
        <List>
          {config.fields.map((field, index) => {
            if (field.type === 'image') {
              return (
                <ListItem key={index}>
                  <ListItemText className={classes.primaryTypography} primary={field.label} />
                  <ListItemAvatar>
                    <EntityImage
                      src={data[field.name] ? `${env.REACT_APP_BACKEND_IMAGES_PATH}${data[field.name]}` : undefined}
                    />
                  </ListItemAvatar>
                </ListItem>
              );
            }

            if (field.name === 'dueDate' && data[field.name]) {
              const date = new Date(data[field.name]);

              return (
                <ListItem key={index}>
                  <ListItemText
                    className={classes.primaryTypography}
                    primary={field.label}
                    secondary={formatDate(date) ?? '-'}
                  />
                </ListItem>
              );
            }

            return (
              <ListItem key={index}>
                <ListItemText
                  className={classes.primaryTypography}
                  primary={field.label}
                  secondary={data[field.name] ?? '-'}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
};
