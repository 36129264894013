import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { IColumn } from 'components/Table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { getCorrectedText } from 'utils/helpers';
import defaultImage from 'assets/png/default-user.png';
import { env } from 'store/env';
import { EntityImage } from 'components/EntityImage';

type ITableColumns = {
  [key: string]: IColumn[];
};

const DATE_FORMAT = 'DD/MM/YYYY';

export const dateFormatter = (value: string): any => (value ? dayjs(value).format(DATE_FORMAT) : null);
export const linkFormatter = (value: any, path: string, pathID?: string): ReactNode => (
  <Link style={{ color: '#1554d5' }} to={`${path}/show/${pathID}`}>
    {value}
  </Link>
);
export const linkViewFormatter = (value: string, path: string, isEdit?: boolean): ReactNode => (
  <Link style={{ color: '#1554d5' }} to={`${path}/${isEdit ? 'edit' : 'show'}/${value}`}>
    <IconButton>{isEdit ? <EditIcon /> : <VisibilityIcon />}</IconButton>
  </Link>
);

export const linkViewCustomUrlFormatter = (url: string): ReactNode => (
  <Link style={{ color: '#1554d5' }} to={`${url}`}>
    <IconButton>
      <VisibilityIcon />
    </IconButton>
  </Link>
);

export const tableColumns: ITableColumns = {
  maintenance: [
    {
      id: 'code',
      label: 'Code',
      format: (value: string, row: any) => linkFormatter(value, 'maintenance', row.id),
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'event',
      label: 'Event',
      format: (value: any) => (
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <Link style={{ color: '#1554d5', marginRight: 5 }} to={`events/show/${value?.code}`}>
            {value?.code}
          </Link>
          {getCorrectedText(value?.name)}
        </div>
      ),
    },
    {
      id: 'startDate',
      label: 'Start date',
      format: dateFormatter,
    },
    {
      id: 'dueDate',
      label: 'Due date',
      format: dateFormatter,
    },
    {
      id: 'createdAt',
      label: 'Created',
      format: dateFormatter,
    },
    {
      id: 'status',
      label: 'Status',
      format: (value: any) => value?.name,
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'maintenance'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'maintenance', true),
    },
  ],
  cars: [
    {
      id: 'code',
      label: 'Code',
      format: (value: string, row: any) => linkFormatter(value, 'cars', row.id),
    },
    {
      id: 'images',
      label: 'Photo',
      format: (value: any) =>
        value?.[0]?.location ? (
          <EntityImage alt="clrt-car" src={`${env.REACT_APP_BACKEND_IMAGES_PATH}${value?.[0]?.location}`} />
        ) : null,
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'color',
      label: 'Color',
    },
    {
      id: 'racerNumber',
      label: 'Racer',
    },
    {
      id: 'createdAt',
      label: 'Created',
      format: dateFormatter,
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'cars'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'cars', true),
    },
  ],
  tasks: [
    {
      id: 'code',
      label: 'Code',
      format: (value: string, row: any) => linkFormatter(value, 'tasks', row.id),
    },
    {
      id: 'maintenance',
      label: 'Event',
      format: (value: any) => (
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <Link style={{ color: '#1554d5', marginRight: 5 }} to={`events/show/${value?.event?.code}`}>
            {value?.event?.code}
          </Link>
          {getCorrectedText(value?.event?.name)}
        </div>
      ),
    },
    {
      id: 'maintenance',
      label: 'Maintenance',
      format: (value: any) => (
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <Link style={{ color: '#1554d5', marginRight: 5 }} to={`maintenance/show/${value?.code}`}>
            {value?.code}
          </Link>
          {getCorrectedText(value?.name)}
        </div>
      ),
    },
    {
      id: 'type',
      label: 'Type',
      format: (value: any) => value?.name,
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'assignee',
      label: 'Assignee',
      format: (value: any) => value?.fullName,
    },
    {
      id: 'dueDate',
      label: 'Due date',
      format: dateFormatter,
    },
    {
      id: 'createdAt',
      label: 'Created',
      format: dateFormatter,
    },
    {
      id: 'status',
      label: 'Status',
      format: (value: any) => value?.name,
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'tasks'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'tasks', true),
    },
  ],
  events: [
    {
      id: 'code',
      label: 'Code',
      format: (value: string, row: any) => linkFormatter(value, 'events', row.id),
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'type',
      label: 'Type',
      format: (value: any) => value?.name,
    },
    {
      id: 'dateStart',
      label: 'Start date',
      format: dateFormatter,
    },
    {
      id: 'dateEnd',
      label: 'Due date',
      format: dateFormatter,
    },
    {
      id: 'createdAt',
      label: 'Created',
      format: dateFormatter,
    },
    {
      id: 'championship',
      label: 'Championship',
      format: (value: any) => value?.name,
    },
    {
      id: 'trackName',
      label: 'Track',
    },
    {
      id: 'status',
      label: 'Status',
      format: (value: any) => value?.name,
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'events'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'events', true),
    },
  ],
  'car-parts': [
    {
      id: 'code',
      label: 'Code',
      format: (value: string, row: any) => linkFormatter(value, 'car-parts', row.id),
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'serial',
      label: 'Serial number',
    },
    {
      id: 'category',
      label: 'Category',
      format: (value: any) => value?.name,
    },
    {
      id: 'category',
      label: 'Sub Category',
      format: (value: any) => value?.name,
    },
    {
      id: 'mileageCurrentTime',
      label: 'Mileage Time',
    },
    {
      id: 'mileageCurrentDistance',
      label: 'Mileage Distance',
    },
    {
      id: 'createdAt',
      label: 'Created',
      format: dateFormatter,
    },
    {
      id: 'status',
      label: 'Status',
      format: (value: any) => value?.name,
    },
    {
      id: 'allocation',
      label: 'Allocation',
      format: (value: any) => value?.type,
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'car-parts'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'car-parts', true),
    },
  ],
  users: [
    {
      id: 'avatar',
      label: 'Photo',
      format: (value: any) => (
        <EntityImage
          alt="clrt-car"
          src={value?.location ? `${env.REACT_APP_BACKEND_IMAGES_PATH}${value?.location}` : defaultImage}
        />
      ),
    },
    {
      id: 'fullName',
      label: 'Full name',
    },
    {
      id: 'username',
      label: 'Username',
    },
    {
      id: 'role',
      label: 'Role',
    },
    {
      id: 'organization',
      label: 'Organization',
      format: (value: any) => value?.name,
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'users'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'users', true),
    },
  ],
  'task-templates': [
    {
      id: 'taskType',
      label: 'Type',
      format: (value: any) => value?.name,
    },
    {
      id: 'createdAt',
      label: 'Created',
      format: dateFormatter,
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'task-templates'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'task-templates', true),
    },
  ],
  organizations: [
    {
      id: 'logo',
      label: 'Logo',
      format: (value: any) =>
        value?.location ? (
          <EntityImage alt="clrt-car" src={`${env.REACT_APP_BACKEND_IMAGES_PATH}${value?.location}`} />
        ) : (
          '-'
        ),
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'organizations'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'organizations', true),
    },
  ],
  dictionaries: [
    {
      id: 'type',
      label: 'Name',
    },
    {
      id: 'createdAt',
      label: 'Created',
      format: dateFormatter,
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'dictionaries'),
    },
    {
      id: 'id',
      label: '',
      format: (value: string) => linkViewFormatter(value, 'dictionaries', true),
    },
  ],
};
