import React, { useState } from 'react';
import type { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStore } from 'store';
import { Api } from 'utils/Api';
import { checkFileMimeType, checkFileMaxSize, formatBytes } from 'utils/commonHelpers';
import { MAX_FILE_UPLOAD_SIZE_BYTES } from 'utils/contstants';
import { MEDIA_FILE_URL } from 'store/api';
import defaultImage from 'assets/png/default-user.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldComponentProps } from './types';
import { UploadFormFieldConfig } from '../config';
import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { env } from 'store/env';
import { EntityImage } from 'components/EntityImage';
import { StatusCodes } from 'http-status-codes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    files: {
      backgroundColor: theme.palette.background.default,
      marginTop: 10,
    },
    label: {
      position: 'relative',
      top: -15,
    },
    uploader: {
      border: '1px solid rgba(0, 0, 0, 0.42)',
      borderRadius: 4,
      width: 50,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      marginLeft: 20,
    },
    input: {
      display: 'none',
    },
  })
);

export const UploaderFormControl: React.FC<FieldComponentProps<UploadFormFieldConfig>> = ({
  field,
  value,
  onChange,
}) => {
  const classes = useStyles();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const {
    state: { user },
  } = useStore();
  const [hasErrorFileType, setHasErrorFileType] = useState<boolean>(false);
  const [hasErrorFileSize, setHasErrorFileSize] = useState<boolean>(false);

  const acceptableMimeType = field.fileType === 'image' ? 'image/*' : 'application/pdf';

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>): void => {
    setHasErrorFileType(false);
    setHasErrorFileSize(false);

    setIsBusy(true);

    const helper = async (file: File): Promise<void> => {
      const formData: FormData = new FormData();
      formData.append('userId', user?.sub || '');
      formData.append('files', file);

      try {
        const res = await Api.post(MEDIA_FILE_URL, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        onChange(field?.multiple ? [res?.data?.[0], ...(value ?? [])] : res?.data?.[0]);
      } catch (error) {
        switch ((error as any)?.response?.status) {
          case StatusCodes.UNSUPPORTED_MEDIA_TYPE:
            setHasErrorFileType(true);
            break;
          case StatusCodes.REQUEST_TOO_LONG:
            setHasErrorFileSize(true);
            break;
        }
      } finally {
        setIsBusy(false);
      }
    };

    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];

        if (!checkFileMimeType(file, acceptableMimeType)) {
          setHasErrorFileType(true);
          setIsBusy(false);
          break;
        }

        if (!checkFileMaxSize(file, MAX_FILE_UPLOAD_SIZE_BYTES)) {
          setHasErrorFileSize(true);
          setIsBusy(false);
          break;
        }

        helper(file);
      }
    }
  };

  const onDeleteFile = (file: any) => {
    onChange(field?.multiple ? (value ?? []).filter((f: any) => f?.location !== file?.location) : null);
  };

  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor="component-simple" className={classes.label}>
        {field.label}
      </InputLabel>
      <input
        accept={acceptableMimeType}
        className={classes.input}
        id={`upload-input-${field.name}`}
        type="file"
        onChange={onChangeFile}
        multiple={field.multiple}
      />
      <div className={classes.container}>
        <label htmlFor={`upload-input-${field.name}`} className={classes.uploader}>
          {isBusy ? (
            <CircularProgress />
          ) : (
            <IconButton color="primary" aria-label="upload picture" component="span">
              {field.fileType === 'image' ? <PhotoCamera /> : <PictureAsPdf />}
            </IconButton>
          )}
        </label>
        {hasErrorFileType && (
          <FormHelperText error={true}>The file you selected is not valid (should be {field.fileType}).</FormHelperText>
        )}
        {hasErrorFileSize && (
          <FormHelperText error={true}>
            The file you selected is too large (should be not greater then {formatBytes(MAX_FILE_UPLOAD_SIZE_BYTES)}).
          </FormHelperText>
        )}
        <List dense className={classes.files}>
          {field.multiple &&
            value?.map((item: any, key: number) => {
              return (
                <ListItem key={key}>
                  <ListItemAvatar>
                    <EntityImage
                      src={
                        field.fileType === 'image'
                          ? `${env.REACT_APP_BACKEND_IMAGES_PATH}${item?.location || item || defaultImage}`
                          : undefined
                      }>
                      <PictureAsPdf />
                    </EntityImage>
                  </ListItemAvatar>
                  <ListItemText primary={item?.fileName} secondary={formatBytes(item?.fileSize ?? 0)} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onDeleteFile(item)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          {!field.multiple && value && (
            <ListItem>
              <ListItemAvatar>
                <EntityImage
                  src={
                    field.fileType === 'image'
                      ? `${env.REACT_APP_BACKEND_IMAGES_PATH}${value?.location || value || defaultImage}`
                      : undefined
                  }>
                  <PictureAsPdf />
                </EntityImage>
              </ListItemAvatar>
              <ListItemText primary={value?.fileName} secondary={formatBytes(value?.fileSize ?? 0)} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => onDeleteFile(value)} edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </div>
    </FormControl>
  );
};
