import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FieldComponentProps } from './types';
import { CarPartAllocationConfig, SelectFieldOption } from '../index';

export const CarPartAllocationControl: React.FC<FieldComponentProps<CarPartAllocationConfig>> = ({
  field,
  value,
  onChange,
  onBlur,
  error,
}) => {
  const { state } = useStore();
  const [targetOptions, setTargetOptions] = useState<SelectFieldOption[]>([]);
  const [defaultVal, setDefaultVal] = useState({ type: '', targetId: '' });

  useEffect(() => {
    if (!value?.type) {
      return;
    }

    switch (value?.type) {
      case 'stock':
        setTargetOptions(field.getStockTypeOptions(state));
        break;
      case 'car_part':
        field.getCarPartOptions(state).then(setTargetOptions);
        break;
      case 'car':
        field.getCarOptions(state).then(setTargetOptions);
        break;
    }

    setDefaultVal((prevState) => ({
      ...prevState,
      type: value?.type,
    }));
  }, [value?.type]);

  useEffect(() => {
    setDefaultVal((prevState) => ({
      ...prevState,
      targetId: value?.targetId || '',
    }));
  }, [value?.targetId]);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel shrink={true} variant="standard" htmlFor={field.name as string}>
          {field.label}
        </InputLabel>
        <Select
          error={!!error}
          labelId={field.name as string}
          value={defaultVal?.type}
          onBlur={onBlur}
          onChange={(e) =>
            onChange({
              targetId: null,
              type: e.target.value,
            })
          }
          fullWidth>
          <MenuItem value={'stock'}>Stock</MenuItem>
          <MenuItem value={'car_part'}>Car part</MenuItem>
          <MenuItem value={'car'}>Car</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel />
        <Select
          error={!!error}
          value={defaultVal?.targetId}
          onBlur={onBlur}
          onChange={(e) =>
            onChange({
              targetId: e.target.value,
              type: value?.type,
            })
          }
          fullWidth>
          {targetOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={!!error}>{error}</FormHelperText>
      </FormControl>
    </>
  );
};
