import { DataSource, dicToOptionMapper, FormConfig } from 'components/DynamicForm';
import { ICarPart } from 'types/car-parts';
import { CAR_MODEL_URL, CAR_PART_CATEGORY_URL, CAR_PART_URL, CAR_URL, GET_WALLET_URL } from 'store/api';
import * as Yup from 'yup';
import { Api } from '../../utils/Api';
import { List } from '../../types/list';
import { ICar, TCarModel } from '../../types/cars';
import { createParams } from '../../utils/helpers';
import { TFile } from '../../types/TFile';
import NetworkService from '../../services/symbol/NetworkService';
import TransactionService from '../../services/symbol/TransactionService';
import AccountService from '../../services/symbol/AccountService';
import { Account, NetworkType, PublicAccount } from 'symbol-sdk';
import { RootState } from '../../store/state';

export type CarPartFormValues = {
  modelId: any;
  markId: any;
  name: string;
  serial: string;
  parentCategoryId?: string;
  categoryId: string;
  description: string;
  measurementTypeId: string;
  mileageCurrentTime: number | string;
  mileageMaxTime: number | string;
  mileageCurrentDistance: number | string;
  mileageMaxDistance: number | string;
  manufactureExpirationDate: number | string;
  manufactureDate: string;
  number: number | string;
  quantity: number | string;
  price: string;
  allocation?: any;
  images: TFile[];
  documents: TFile[];
  organizationId?: string;
};

const dataSource: DataSource<ICarPart, CarPartFormValues> = {
  get: (id) => Api.get(`${CAR_PART_URL}/${id}`).then(({ data }) => data),
  update: async (id, values) => {
    console.log('update car part:', values);
    const response = await Api.put(`${CAR_PART_URL}/${id}`, values);

    if (values.allocation && values.allocation.type === 'car') {
      const network = await NetworkService.getNetwork();
      const { data: carWallet } = await Api.get(GET_WALLET_URL('car', values.allocation.targetId));
      const hashSum: string = TransactionService.generateHush(values as any, 'car');
      const _userWallet: string | null = localStorage.getItem('wallet');
      const userWallet = _userWallet ? JSON.parse(_userWallet) : {};
      const userAddress: string = AccountService.getAddressFromPrivateKey(userWallet.privateKey, network.type);
      const { ownedMosaics, balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(userAddress, network);
      const { address }: any = PublicAccount.createFromPublicKey(
        carWallet.publicKey,
        network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
      );
      if (balance > 0) {
        const userAccount = Account.createFromPrivateKey(
          userWallet.privateKey,
          network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
        );
        const path = `m/44'/${network.type === 'testnet' ? '1' : '4343'}'/0'/0'/0'`;
        const selectedAccount = AccountService.symbolAccountToAccountModel(userAccount, 'wallet', 'hd', path);
        await TransactionService.makeTransaction(address.address, hashSum, ownedMosaics, network, selectedAccount);
      }
    }
    return response;
  },
  create: async (values) => {
    const response = await Api.post(`${CAR_PART_URL}`, values);
    if (values.allocation && values.allocation.type === 'car') {
      const network = await NetworkService.getNetwork();
      const { data: carWallet } = await Api.get(GET_WALLET_URL('car', values.allocation.targetId));
      const hashSum: string = TransactionService.generateHush(values as any, 'car');
      const _userWallet: string | null = localStorage.getItem('wallet');
      const userWallet = _userWallet ? JSON.parse(_userWallet) : {};
      const userAddress: string = AccountService.getAddressFromPrivateKey(userWallet.privateKey, network.type);
      const { ownedMosaics, balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(userAddress, network);
      const { address }: any = PublicAccount.createFromPublicKey(
        carWallet.publicKey,
        network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
      );
      if (balance > 0) {
        const userAccount = Account.createFromPrivateKey(
          userWallet.privateKey,
          network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET
        );
        const path = `m/44'/${network.type === 'testnet' ? '1' : '4343'}'/0'/0'/0'`;
        const selectedAccount = AccountService.symbolAccountToAccountModel(userAccount, 'wallet', 'hd', path);
        await TransactionService.makeTransaction(address.address, hashSum, ownedMosaics, network, selectedAccount);
      }
    }
    return response;
  },
  delete: (id) => Api.delete(`${CAR_PART_URL}/${id}`),
};

export const CAR_PART_FORM_CONFIG: FormConfig<ICarPart, CarPartFormValues> = {
  dataSource,
  mapSourceToValues: (data) => ({
    modelId: data.model.id,
    markId: data.model.mark.id,
    name: data.name,
    serial: data.serial,
    parentCategoryId: data.category?.parent?.id,
    categoryId: data.category.id,
    description: data.description,
    measurementTypeId: data?.measurementType?.id,
    mileageCurrentTime: data.mileageCurrentTime,
    mileageMaxTime: data.mileageMaxTime,
    mileageCurrentDistance: data.mileageCurrentDistance,
    mileageMaxDistance: data.mileageMaxDistance,
    manufactureExpirationDate: data.manufactureExpirationDate,
    manufactureDate: data.manufactureDate,
    number: data.number,
    quantity: data.quantity,
    price: data.price,
    images: data.images,
    documents: data.documents,
    allocation: data?.allocation
      ? {
          type: data?.allocation?.type,
          targetId: ((allocation) => {
            switch (allocation?.type) {
              case 'car':
                return allocation?.targetCar?.id;
              case 'car_part':
                return allocation?.targetCarPart?.id;
              case 'stock':
                return allocation?.targetStock?.id;
              default:
                return null;
            }
          })(data?.allocation),
        }
      : null,
  }),
  defaultValues: {
    modelId: '',
    markId: '',
    name: '',
    serial: '',
    categoryId: '',
    description: '',
    measurementTypeId: '',
    mileageCurrentTime: '',
    mileageMaxTime: '',
    mileageCurrentDistance: '',
    mileageMaxDistance: '',
    manufactureExpirationDate: '',
    manufactureDate: '',
    number: '',
    quantity: '',
    price: '',
    images: [],
    documents: [],
  },
  /**[NOTE]: Order of fields array is important */
  fields: [
    {
      name: 'markId',
      label: 'Mark',
      type: 'select',
      validation: Yup.string().required('Please select mark'),
      optionsSelector: ({ carMarks }) => carMarks.data.map((item) => dicToOptionMapper(item, false)),
    },
    {
      name: 'modelId',
      label: 'Model',
      type: 'select',
      connectedField: 'markId',
      validation: Yup.string().required('Please select model'),
      getOptions: async (markId) => {
        if (!markId) return Promise.resolve([]);
        const {
          data: { data },
        } = await Api.get<List<TCarModel>>(
          CAR_MODEL_URL +
            createParams({
              offset: 0,
              limit: 1000,
              'filter[mark][id]': markId,
            })
        );
        return data.map(({ id, name }) => ({ value: id, label: name }));
      },
    },
    {
      name: 'name',
      type: 'textField',
      required: true,
      label: 'Name',
      validation: Yup.string().required('Please enter car name').max(100, 'Invalid value'),
    },
    {
      name: 'serial',
      label: 'Serial number',
      type: 'textField',
      validation: Yup.string().max(100, 'Invalid value'),
    },
    {
      name: 'parentCategoryId',
      label: 'Category',
      validation: Yup.string().required('Please choose category'),
      type: 'select',
      getOptions: async () => {
        const {
          data: { data },
        } = await Api.get<List<any>>(
          CAR_PART_CATEGORY_URL +
            createParams({
              offset: 0,
              limit: 1000,
            })
        );
        return data.filter(({ parent }) => parent === null).map(({ id, name }) => ({ value: id, label: name }));
      },
    },
    {
      name: 'categoryId',
      label: 'Sub category',
      validation: Yup.string().required('Please choose sub category'),
      type: 'select',
      connectedField: 'parentCategoryId',
      getOptions: async (parentCategoryId) => {
        if (!parentCategoryId) return Promise.resolve([]);
        const {
          data: { data },
        } = await Api.get<List<any>>(
          CAR_PART_CATEGORY_URL +
            createParams({
              offset: 0,
              limit: 1000,
            })
        );
        return data
          .filter(({ parent }) => parent?.id === parentCategoryId)
          .map(({ id, name }) => ({ value: id, label: name }));
      },
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textField',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
    },
    {
      name: 'number',
      label: 'Part Number',
      type: 'textField',
    },
    {
      name: 'quantity',
      label: 'Quantity',
      type: 'textField',
      validation: Yup.number(),
    },
    {
      name: 'price',
      label: 'Price per part',
      type: 'textField',
      validation: Yup.number(),
    },
    {
      name: 'allocation',
      label: 'Allocation',
      type: 'carPartAllocation',
      validation: Yup.object(),
      getCarPartOptions: async () => {
        const {
          data: { data },
        } = await Api.get<List<ICarPart>>(
          CAR_PART_URL +
            createParams({
              offset: 0,
              limit: 100,
            })
        );
        return data.map(({ id, name, code }) => ({ value: id, label: `${code} ${name}` }));
      },
      getCarOptions: async () => {
        const {
          data: { data },
        } = await Api.get<List<ICar>>(
          CAR_URL +
            createParams({
              offset: 0,
              limit: 100,
            })
        );
        return data.map(({ id, name, code }) => ({ value: id, label: `${code} ${name}` }));
      },
      getStockTypeOptions: ({ carPartStockDictionary }) =>
        carPartStockDictionary.map((item) => dicToOptionMapper(item, false)),
    },
    {
      name: 'measurementTypeId',
      label: 'Measurement Type',
      validation: Yup.string().nullable().max(100, 'Invalid value'),
      type: 'select',
      optionsSelector: ({ measurementTypeDictionary }) =>
        measurementTypeDictionary.map((item) => dicToOptionMapper(item, false)),
    },
    {
      name: 'mileageCurrentTime',
      label: 'Current time',
      type: 'textField',
    },
    {
      name: 'mileageMaxTime',
      label: 'Maximum time',
      type: 'textField',
    },
    {
      name: 'mileageCurrentDistance',
      label: 'Current Distance',
      type: 'textField',
    },
    {
      name: 'mileageMaxDistance',
      label: 'Maximum distance',
      type: 'textField',
    },
    {
      name: 'manufactureDate',
      label: 'Manufacture date',
      type: 'textField',
      inputType: 'date',
    },
    {
      name: 'manufactureExpirationDate',
      label: 'Expiration date',
      type: 'textField',
      inputType: 'date',
    },
    {
      name: 'organizationId',
      label: 'Organization',
      type: 'select',
      isDisabled: ({ user }: RootState, mode) => user?.role !== 'admin' || mode === 'edit',
      optionsSelector: ({ organizations }) => organizations.data.map((item) => dicToOptionMapper(item, false)),
      validation: Yup.string().uuid().required('Please select Organization.'),
    },
    {
      name: 'images',
      type: 'upload',
      fileType: 'image',
      label: 'Photos',
      multiple: true,
    },
    {
      name: 'documents',
      type: 'upload',
      fileType: 'document',
      label: 'Documents',
      multiple: true,
    },
  ],
};
