import React from 'react';
import { Avatar } from '@material-ui/core';

interface IEntityImageProps {
  src?: string;
  alt?: string;
  children?: React.ReactNode;
  className?: string;
}

export const EntityImage = ({ src, children, alt, className }: IEntityImageProps) => {
  return (
    <Avatar
      variant={'rounded'}
      imgProps={{ style: { objectFit: 'contain' } }}
      alt={alt}
      src={src}
      className={className}>
      {children}
    </Avatar>
  );
};
