import dayjs from 'dayjs';

type DateTimeString = {
  getTime: () => string | null;
  getDate: () => string | null;
};

export const dateTimeString = (dateTimeString: string): DateTimeString => {
  const date = dayjs(dateTimeString);

  const getTime = (): string | null => {
    return date.isValid() ? date.format('YYYY-MM-DD') : null;
  };

  const getDate = (): string | null => {
    return date.isValid() ? date.format('YYYY-MM-DD') : null;
  };

  return {
    getTime,
    getDate,
  };
};
