import {
  createRestDataSource,
  dataProvider,
  dicToOptionMapper,
  formatIsoString,
  FormConfig,
} from 'components/DynamicForm';
import { IMaintenance } from 'types/maintenance';
import { MAINTENANCE_URL, EVENTS_URL } from 'store/api';
import * as Yup from 'yup';
import { RootState } from '../../store/state';

export type MaintenanceFormValues = {
  eventId: string;
  name: string;
  startDate: string;
  dueDate: string;
  organizationId?: string;
};

export const MAINTENANCE_FORM_CONFIG: FormConfig<IMaintenance, MaintenanceFormValues> = {
  dataSource: createRestDataSource(MAINTENANCE_URL),
  additionalDataProvider: dataProvider(EVENTS_URL),
  mapSourceToValues: (data) => ({
    name: data?.name,
    eventId: data?.event?.id,
    dueDate: formatIsoString(data?.dueDate),
    startDate: formatIsoString(data?.startDate),
  }),
  defaultValues: {
    name: '',
    eventId: '',
    dueDate: '',
    startDate: '',
  },
  fields: [
    {
      name: 'organizationId',
      label: 'Organization',
      type: 'select',
      isDisabled: ({ user }: RootState, mode) => user?.role !== 'admin' || mode === 'edit',
      optionsSelector: ({ organizations }) => organizations.data.map((item) => dicToOptionMapper(item, false)),
      validation: Yup.string().uuid().required('Please select Organization.'),
    },
    {
      name: 'eventId',
      type: 'select',
      label: 'Event',
      optionsSelector: ({ events }) => events.data.map((item) => dicToOptionMapper(item, true)),
    },
    {
      name: 'name',
      label: 'Name',
      type: 'textField',
      required: true,
      validation: Yup.string()
        .required('Please enter the name of the maintenance.')
        .max(100, 'The name must be less than 100 characters'),
    },
    {
      name: 'startDate',
      label: 'Start date',
      type: 'textField',
      inputType: 'date',
      required: true,
      validation: Yup.date().required('Please choose the start date of the event.'),
    },
    {
      name: 'dueDate',
      label: 'Due date',
      type: 'textField',
      inputType: 'date',
      required: false,
      validation: Yup.string(),
    },
  ],
};
